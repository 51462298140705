import { useInterval } from 'hooks/useInterval'
import { useState } from 'react'
import cx from 'classnames'
import { Maybe } from 'types/generated/contentful-types'
const PHRASE_ROTATION_INTERVAL_IN_MS = 2500

interface RotatingTextProps {
  rotatingTextContent: Maybe<string>[]
}
export const RotatingText = ({ rotatingTextContent }: RotatingTextProps) => {
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0)
  const phrases = rotatingTextContent || []

  useInterval(() => {
    setCurrentPhraseIndex((currIdx) => (currIdx + 1) % phrases.length)
  }, PHRASE_ROTATION_INTERVAL_IN_MS)

  return (
    <div>
      <div className="relative grid">
        {phrases.map((phrase, index) => (
          <div
            key={index}
            aria-hidden={index !== currentPhraseIndex}
            className={cx({
              'next-phrase': index === currentPhraseIndex,
              'old-phrase': (index + 1) % phrases.length === currentPhraseIndex,
              'opacity-0': index !== currentPhraseIndex,
            })}
            style={{ gridArea: '1 / 1 / 2 / 2' }}
          >
            {phrase}
          </div>
        ))}
      </div>
    </div>
  )
}
